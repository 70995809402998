<template>
  <v-dialog v-model="value" max-width="800px">
    <v-card>
      <v-card-title class="bg-gradient-primary-2">
        <span class="headline white--text">PA Request Details</span>
      </v-card-title>
      <div v-if="checkObject(paRequest)" class="body">
        <v-card-text class="pt-4">
          <InfoBlock label="User" :value="paRequest.username" />
          <InfoBlock label="Request Date" :value="formateDate(paRequest.date)" />
          <InfoBlock label="Request Action" :value="paRequest.type" />
          <InfoBlock label="Name Of Security" :value="paRequest.security" />
          <InfoBlock label="Ticker" :value="paRequest.details.ticker" />
          <InfoBlock label="Exchange" :value="paRequest.details.exchange" />
          <InfoBlock label="Quantity" :value="paRequest.details.quantity" />
          <InfoBlock label="GTC Order" :value="paRequest.details.gtcOrder" />
          <InfoBlock label="Investment class" :value="paRequest.details.investmentClass" />
          <InfoBlock label="Account Information" :value="paRequest.details.accInfo" />
          <InfoBlock label="Broker" :value="paRequest.details.broker" />
          <InfoBlock
            label="Initial Approver"
            :value="removeUndefinedFromString(paRequest.details.selectedInitialApprover.name)"
          />
          <InfoBlock
            label="Subsequent Approver"
            :value="getSubsequentApproverNames(paRequest.details.subsequentApprovers)"
          />
          <InfoBlock label="Requestor Comment" :value="paRequest.details.requestorComment" />
        </v-card-text>
        <v-card-text class="declaration">
          <div class="elevation-2 rounded">
            <div class="heading bg-gradient-primary-2 elevation-2 rounded">Declaration</div>
            <div class="py-2 px-4" v-html="paRequest.declaration"></div>
          </div>
        </v-card-text>
        <v-card-text class="approvals">
          <div class="heading bg-gradient-primary-2 elevation-2 rounded">Approvals</div>
          <InfoBlock label="Initial Approver" use-slot>
            <template #content>
              <span v-if="paRequest.details.cancelRequest" class="error--text font-weight-bold">
                Cancelled
              </span>
              <span v-else-if="paRequest.status === 'Expired'" class="error--text font-weight-bold">
                Expired
              </span>
              <div class="d-flex" v-else-if="paRequest.approval.initial.approved">
                <div class="success--text font-weight-bold">Approved</div>
                <div class="pl-2">
                  <strong>By:</strong>
                  {{ paRequest.approval.initial.by || 'N/A' }}
                  ({{ paRequest.approval.initial.date }})
                </div>
              </div>
              <div
                class="d-flex"
                v-else-if="!paRequest.approval.initial.approved && paRequest.approval.initial.date"
              >
                <div class="error--text font-weight-bold">Rejected</div>
                <div class="pl-2">
                  <strong>By:</strong>
                  {{ paRequest.approval.initial.by || 'N/A' }}
                  ({{ paRequest.approval.initial.date }})
                </div>
              </div>
              <span v-else class="font-weight-bold">Waiting for approval</span>
            </template>
          </InfoBlock>
          <InfoBlock label="Subsequent Approver" use-slot>
            <template #content>
              <template v-if="!initialApproverOnly">
                <span v-if="paRequest.details.cancelRequest" class="error--text font-weight-bold">
                  Cancelled
                </span>
                <span
                  v-else-if="paRequest.status === 'Expired'"
                  class="error--text font-weight-bold"
                >
                  Expired
                </span>
                <div class="d-flex" v-else-if="paRequest.approval.subsequent.approved">
                  <div class="success--text font-weight-bold">Approved</div>
                  <div class="pl-2">
                    <strong>By:</strong>
                    {{ paRequest.approval.subsequent.by || 'N/A' }}
                    ({{ paRequest.approval.subsequent.date }})
                  </div>
                </div>
                <div
                  class="d-flex"
                  v-else-if="
                    !paRequest.approval.subsequent.approved && paRequest.approval.subsequent.date
                  "
                >
                  <div class="error--text font-weight-bold">Rejected</div>
                  <div class="pl-2">
                    <strong>By:</strong>
                    {{ paRequest.approval.subsequent.by || 'N/A' }}
                    ({{ paRequest.approval.subsequent.date }})
                  </div>
                </div>
                <span v-else class="font-weight-bold">Waiting for approval</span>
              </template>
              <span v-else> N/A </span>
            </template>
          </InfoBlock>
        </v-card-text>
      </div>
      <v-card-actions class="py-5">
        <v-spacer></v-spacer>
        <ac-button title="Close" color="error" outlined @click="onClose" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment-timezone'
import { mapGetters } from 'vuex'

export default {
  name: 'PaRequestDetailsModal',
  components: {
    AcButton: () => import('@/components/AcButton'),
    InfoBlock: () => import('@/components/PaDealing/PaRequests/PaRequestDetailsModal/InfoBlock'),
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    paRequest: {
      type: Object,
      default: () => {},
    },
    initialApproverOnly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('paDealing', ['companyStaff']),
  },
  methods: {
    onClose() {
      this.$emit('input', false)
    },
    formateDate(date) {
      if (!date) return null
      return moment(date).format('DD MMM YYYY HH:MM:SS')
    },
    getSubsequentApproverNames(list) {
      if (!list?.length) return 'N/A'

      let filteredArray = []

      list.forEach(id => {
        const result = this.companyStaff.find(item => item.id === id)
        if (result) {
          filteredArray.push(result)
        }
      })

      filteredArray = filteredArray.map(record => {
        return `${record.firstname} ${record.surname || ''}`
      })
      return filteredArray.join(', ')
    },
  },
}
</script>

<style lang="scss" scoped>
.body {
  max-height: 70vh;
  overflow-y: auto;
}
.declaration,
.approvals {
  .heading {
    color: white;
    font-weight: 600;
    width: 30%;
    white-space: nowrap;
    padding: 0.5em 1em;
    margin-bottom: 0.5em;
    font-size: 1.2em;
  }
}
</style>
